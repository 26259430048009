import React from 'react';
import { InlineWidget } from 'react-calendly';
import './CalendlyStyles.css'; // Import the CSS file

const Calendly = () => {
  return (
    <div style={styles.container}>
      <InlineWidget url="https://calendly.com/willichrt" />
    </div>
  );
};

const styles = {
  container: {
  },
};

export default Calendly;
