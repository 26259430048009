import Parse from "parse";

// Initializing the SDK. 
// You need to copy BOTH the Application ID and the Javascript Key from: Dashboard->App Settings->Security & Keys 
Parse.initialize('xQvh1ruePSHj41lD24p8dyKhUY5lPMDCL71VvQes','6sBDs4OLGUllJzIOoklgJzryGGlQJ0WGqmZkVGmg');
Parse.serverURL = 'https://parseapi.back4app.com/';

export async function fetchProperties() {
    try {
        // create your Parse Query using the Properties Class you've created
        let query = new Parse.Query('Properties');
        // run the query to retrieve all objects on Person class, optionally you can add your filters
        let queryResult = await query.find();
        // pick the first result 
        const savedProperties = queryResult;
        // access the Parse Object attributes
        console.log('property id: ', savedProperties[0].get('address'));
        return savedProperties;
    } catch (error) {
        console.log(error);
    }
}

export async function addProperty(property) {
    try {
        const myNewObject = new Parse.Object('Properties');
        myNewObject.set('potentialMonthlyRent', property.potentialMonthlyRent);
        myNewObject.set('address', property.address);
        myNewObject.set('potentialMonthlyMortgageRate', property.potentialMonthlyMortgageRate);
        myNewObject.set('potentialMonthlyRevenue', property.potentialMonthlyRevenue);
        myNewObject.set('potentialLifeTimeLoanCost', property.potentialLifeTimeLoanCost);
        myNewObject.set('homeOwnersInsurance', property.homeOwnersInsurance);
        myNewObject.set('propertyTaxes', property.propertyTaxes);
        myNewObject.set('lender', property.lender);
        myNewObject.set('propertyUrl', property.propertyUrl);

        myNewObject.set('zipCode', property.zipCode);
        myNewObject.set('currentRents', property.currentRents);
        myNewObject.set('miscellaneousCost', property.miscellaneousCost);
        myNewObject.set('notes', property.notes);

        try {
          const result = await myNewObject.save();
          // Access the Parse Object attributes using the .GET method
          console.log('Properties created', result);
        } catch (error) {
          console.error('Error while creating Properties: ', error);
        }
    } catch (error) {
        console.log(error);
    }
}

export async function removeProperty(propertyId) {
    try {
      const Property = Parse.Object.extend('Properties');
      const query = new Parse.Query(Property);
  
      // Retrieve the property by its objectId
      const property = await query.get(propertyId);
  
      // Delete the property
      await property.destroy();
  
      console.log('Property removed successfully');
    } catch (error) {
      console.error('Error while removing property: ', error);
    }
  }


  export async function addLead(lead) {
    try {
      const myNewObject = new Parse.Object('Leads');
  
      myNewObject.set('firstName', lead.firstName);
      myNewObject.set('lastName', lead.lastName);
      myNewObject.set('phoneNumber', lead.phoneNumber);
      myNewObject.set('creditScore', lead.creditScore);
      myNewObject.set('emailAddress', lead.emailAddress);
      myNewObject.set('currentAddress', lead.currentAddress);
      myNewObject.set('employmentStatus', lead.employmentStatus);
      myNewObject.set('annualIncome', lead.annualIncome);
      myNewObject.set('voucher', lead.voucher);
      myNewObject.set('voucherAmount', lead.voucherAmount);
      myNewObject.set('reasonForMoving', lead.reasonForMoving);
      myNewObject.set('preferredMoveInDate', lead.preferredMoveInDate);
      myNewObject.set('numberOfOccupants', lead.numberOfOccupants);
      myNewObject.set('haveYouBeenEvicted', lead.haveYouBeenEvicted);
      myNewObject.set('criminalHistory', lead.criminalHistory);
      myNewObject.set('reference1Name', lead.reference1Name);
      myNewObject.set('reference1Phone', lead.reference1Phone);
      myNewObject.set('reference1Email', lead.reference1Email);
      myNewObject.set('reference2Name', lead.reference2Name);
      myNewObject.set('reference2Phone', lead.reference2Phone);
      myNewObject.set('reference2Email', lead.reference2Email);
      myNewObject.set('qualified', lead.qualified);

      // myNewObject.set('emergencyContactName', lead.emergencyContactName);
      // myNewObject.set('emergencyContactPhone', lead.emergencyContactPhone);
      // myNewObject.set('emergencyContactEmail', lead.emergencyContactEmail);
      // myNewObject.set('certificationsLicenses', lead.certificationsLicenses);
  
      try {
        const result = await myNewObject.save();
        console.log('Lead created', result);
        return result;
      } catch (error) {
        console.error('Error while creating Lead: ', error);
        throw error;
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  }


  // Function to remove a lead
export const removeLead = async (leadId) => {
  const query = new Parse.Query('Leads');

  try {
    const lead = await query.get(leadId);
    try {
      const response = await lead.destroy();
      console.log('Deleted Lead', response);
      return response;
    } catch (error) {
      console.error('Error while deleting Lead', error);
      throw error;
    }
  } catch (error) {
    console.error('Error while retrieving Lead', error);
    throw error;
  }
};

export async function getLeads() {
  const Leads = Parse.Object.extend('Leads');
  const query = new Parse.Query(Leads);

  try {
    const results = await query.find();
    const leads = results.map(object => ({
      id: object.id,
      createdAt: object.createdAt,
      firstName: object.get('firstName'),
      lastName: object.get('lastName'),
      phoneNumber: object.get('phoneNumber'),
      emailAddress: object.get('emailAddress'),
      creditScore: object.get('creditScore'),
      currentAddress: object.get('currentAddress'),
      employmentStatus: object.get('employmentStatus'),
      annualIncome: object.get('annualIncome'),
      voucher: object.get('voucher'),
      voucherAmount: object.get('voucherAmount'),
      reasonForMoving: object.get('reasonForMoving'),
      preferredMoveInDate: object.get('preferredMoveInDate'),
      numberOfOccupants: object.get('numberOfOccupants'),
      haveYouBeenEvicted: object.get('haveYouBeenEvicted'),
      criminalHistory: object.get('criminalHistory'),
      reference1Name: object.get('reference1Name'),
      reference1Phone: object.get('reference1Phone'),
      reference1Email: object.get('reference1Email'),
      reference2Name: object.get('reference2Name'),
      reference2Phone: object.get('reference2Phone'),
      reference2Email: object.get('reference2Email'),
      // emergencyContactName: object.get('emergencyContactName'),
      // emergencyContactPhone: object.get('emergencyContactPhone'),
      // emergencyContactEmail: object.get('emergencyContactEmail'),
      // certificationsLicenses: object.get('certificationsLicenses'),
      qualified: object.get('qualified')
    }));

    console.log('Fetched leads:', leads);
    return leads;
  } catch (error) {
    console.error('Error while fetching Leads', error);
    throw error;
  }
}

  
  
