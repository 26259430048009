import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider } from './AuthContext';
import { FormDataProvider } from './contexts/FormDataContext';
import Login from './Login';
import InvestmentPropertyCalculator from './InvestmentPropertyCalculator';
import PrivateRoute from './PrivateRoute';
import ProspectiveTenantForm from './ProspectiveTenantForm';
import Leads from './Leads';
import Calendly from './Calendy';

const App = () => {
  return (
    <Router>
      <AuthProvider>
        <FormDataProvider>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/login" element={<Login />} />
            <Route path="/app" element={<PrivateRoute><InvestmentPropertyCalculator /></PrivateRoute>} />
            <Route path="/prospectivetenant" element={<ProspectiveTenantForm />} />
            <Route path="/calendy" element={<Calendly />} />
            {/* <Route path="/leads" element={<PrivateRoute><Leads /></PrivateRoute>} /> */}
            <Route path="/leads" element={<PrivateRoute><Leads /></PrivateRoute>} />
          </Routes>
        </FormDataProvider>
      </AuthProvider>
    </Router>
  );
};

export default App;
