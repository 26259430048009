import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormDataProvider, useFormData } from '../src/contexts/FormDataContext';
import { addLead } from './Database';

const ProspectiveTenantForm = ({ children })  => {
  const { setQualifiedLeads, setUnqualifiedLeads } = useFormData();
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [isDisqualified, setIsDisqualified] = useState(false);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    emailAddress: '',
    creditScore: '',
    currentAddress: '',
    employmentStatus: '',
    annualIncome: '',
    reasonForMoving: '',
    preferredMoveInDate: '',
    pets: '',
    numberOfOccupants: '',
    haveYouBeenEvicted: '',
    criminalHistory: '',
    reference1Name: '',
    reference1Phone: '',
    reference1Email: '',
    reference2Name: '',
    reference2Phone: '',
    reference2Email: ''
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const income = parseInt(formData.annualIncome.replace(/[^0-9]/g, ''), 10);
    const creditScore = parseInt(formData.creditScore, 10);
    
    const isDisqualified =
      formData.pets === 'yes' ||
      formData.haveYouBeenEvicted === 'yes' ||
      formData.criminalHistory === 'yes' ||
      creditScore < 650 ||
      (income < 79000 && formData.voucherOrSection8 === 'none');
  
    const leadData = {
      ...formData,
      phoneNumber: parseInt(formData.phoneNumber.replace(/\D/g, ''), 10),
      qualified: !isDisqualified
    };
  
    try {
      const result = await addLead(leadData);
      console.log('Lead added successfully:', result);
  
      if (isDisqualified) {
        setIsDisqualified(true);
      } else {
        navigate('/calendy');
      }
    } catch (error) {
      console.error('Error adding lead:', error);
      alert("There was an error submitting your application. Please try again.");
    }
  };

  const nextStep = () => {
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <div style={styles.stepContainer}>
            <h2 style={styles.heading}>Step 1: Basic Information</h2>
            <div style={styles.formGroup}>
              <label style={styles.label}>First Name</label>
              <input type="text" name="firstName" value={formData.firstName} onChange={handleChange} required style={styles.input} />
            </div>
            <div style={styles.formGroup}>
              <label style={styles.label}>Last Name</label>
              <input type="text" name="lastName" value={formData.lastName} onChange={handleChange} required style={styles.input} />
            </div>
            <div style={styles.formGroup}>
              <label style={styles.label}>Phone Number</label>
              <input type="number" name="phoneNumber" value={formData.phoneNumber} onChange={handleChange} required style={styles.input} />
            </div>
            <div style={styles.formGroup}>
              <label style={styles.label}>Email Address</label>
              <input type="email" name="emailAddress" value={formData.emailAddress} onChange={handleChange} required style={styles.input} />
            </div>
            <div style={styles.formGroup}>
              <label style={styles.label}>Current Address</label>
              <input type="text" name="currentAddress" value={formData.currentAddress} onChange={handleChange} required style={styles.input} />
            </div>
            <div style={styles.formGroup}>
              <label style={styles.label}>Employment Status</label>
              <select name="employmentStatus" value={formData.employmentStatus} onChange={handleChange} required style={styles.select}>
                <option value="">Select...</option>
                <option value="employed">Employed</option>
                <option value="unemployed">Unemployed</option>
                <option value="student">Student</option>
                <option value="retired">Retired</option>
              </select>
            </div>
            <div style={styles.formGroup}>
              <label style={styles.label}>Annual Income</label>
              <select name="annualIncome" value={formData.annualIncome} onChange={handleChange} required style={styles.select}>
                <option value="">Select...</option>
                <option value="$0 to $49,999">$0 to $49,999</option>
                <option value="$50,000 to $79,999">$50,000 to $79,999</option>
                <option value="$80,000 to $99,999">$80,000 to $99,999</option>
                <option value="$100,000 or more">$100,000 or more</option>
              </select>
            </div>
            <div style={styles.formGroup}>
              <label style={styles.label}>Credit Score</label>
              <select name="creditScore" value={formData.creditScore} onChange={handleChange} required style={styles.select}>
                <option value="">Select...</option>
                <option value="300-579">300-579</option>
                <option value="580-649">580-649</option>
                <option value="650-699">650-699</option>
                <option value="700-749">700-749</option>
                <option value="750-850">750-850</option>
              </select>
            </div>
            <div style={styles.formGroup}>
              <label style={styles.label}>Will you be using a voucher or Section 8?</label>
              <select name="voucherOrSection8" value={formData.voucherOrSection8} onChange={handleChange} required style={styles.select}>
                <option value="">Select...</option>
                <option value="voucher">Voucher</option>
                <option value="section8">Section 8</option>
                <option value="none">None</option>
              </select>
            </div>
            {formData.voucherOrSection8 && formData.voucherOrSection8 !== 'none' && (
              <div style={styles.formGroup}>
                <label style={styles.label}>How much does the voucher or Section 8 cover?</label>
                <input type="text" name="voucherCoverage" value={formData.voucherCoverage} onChange={handleChange} required style={styles.input} />
              </div>
            )}
          </div>
        );
      case 2:
        return (
          <div style={styles.stepContainer}>
            <h2 style={styles.heading}>Step 2: Additional Details</h2>
            <div style={styles.formGroup}>
              <label style={styles.label}>Reason for Moving</label>
              <input type="text" name="reasonForMoving" value={formData.reasonForMoving} onChange={handleChange} style={styles.input} />
            </div>
            <div style={styles.formGroup}>
              <label style={styles.label}>Preferred Move-In Date</label>
              <input type="date" name="preferredMoveInDate" value={formData.preferredMoveInDate} onChange={handleChange} style={styles.input} />
            </div>
            <div style={styles.formGroup}>
              <label style={styles.label}>Do you have any pets?</label>
              <select name="pets" value={formData.pets} onChange={handleChange} required style={styles.select}>
                <option value="">Select...</option>
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </select>
            </div>
            <div style={styles.formGroup}>
              <label style={styles.label}>Number of Occupants</label>
              <input type="number" name="numberOfOccupants" value={formData.numberOfOccupants} onChange={handleChange} style={styles.input} />
            </div>
            <div style={styles.formGroup}>
              <label style={styles.label}>Have you ever been evicted?</label>
              <select name="haveYouBeenEvicted" value={formData.haveYouBeenEvicted} onChange={handleChange} style={styles.select}>
                <option value="">Select...</option>
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </select>
            </div>
            <div style={styles.formGroup}>
              <label style={styles.label}>Do you have any criminal history?</label>
              <select name="criminalHistory" value={formData.criminalHistory} onChange={handleChange} style={styles.select}>
                <option value="">Select...</option>
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </select>
            </div>
          </div>
        );
      case 3:
        return (
          <div style={styles.stepContainer}>
            <h2 style={styles.heading}>Step 3: References and Emergency Contact</h2>
            <div style={styles.formGroup}>
              <label style={styles.label}>Reference 1 - Full Name</label>
              <input type="text" name="reference1Name" value={formData.reference1Name} onChange={handleChange} required style={styles.input} />
            </div>
            <div style={styles.formGroup}>
              <label style={styles.label}>Reference 1 - Phone Number</label>
              <input type="text" name="reference1Phone" value={formData.reference1Phone} onChange={handleChange} required style={styles.input} />
            </div>
            <div style={styles.formGroup}>
              <label style={styles.label}>Reference 1 - Email Address</label>
              <input type="email" name="reference1Email" value={formData.reference1Email} onChange={handleChange} required style={styles.input} />
            </div>
            <div style={styles.formGroup}>
              <label style={styles.label}>Reference 2 - Full Name</label>
              <input type="text" name="reference2Name" value={formData.reference2Name} onChange={handleChange} required style={styles.input} />
            </div>
            <div style={styles.formGroup}>
              <label style={styles.label}>Reference 2 - Phone Number</label>
              <input type="text" name="reference2Phone" value={formData.reference2Phone} onChange={handleChange} required style={styles.input} />
            </div>
            <div style={styles.formGroup}>
              <label style={styles.label}>Reference 2 - Email Address</label>
              <input type="email" name="reference2Email" value={formData.reference2Email} onChange={handleChange} required style={styles.input} />
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <FormDataProvider>
      <div style={styles.pageContainer}>
        <header style={styles.header}>
          <h1 style={styles.headerText}>2706 W Warren Prospective Tenant Questionnaire</h1>
        </header>
        {isDisqualified ? (
          <div style={styles.disqualifiedMessage}>
  We're sorry, but we are unable to schedule a showing at this time.
  <br />
  Unfortunately, one or more of our rental criteria have not been met.
  <br />
  Thank you for your understanding.
</div>

        ) : (
          <form onSubmit={handleSubmit} style={styles.formContainer}>
            <div style={styles.stepIndicator}>Step {step} of 3</div>
            {renderStep()}
            
            <div style={styles.buttonContainer}>
              {step > 1 && <button type="button" onClick={prevStep} style={styles.button}>Back</button>}
              {step < 3 && <button type="button" onClick={nextStep} style={styles.button}>Next</button>}
              {step === 3 && <button type="submit" style={styles.submitButton}>Submit</button>}
            </div>
          </form>
        )}
      </div>
    </FormDataProvider>
  );
};

const styles = {
  pageContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: '100vh',
    width: '100%',
    backgroundColor: '#f0f0f0',
  },
  header: {
    width: '100%',
    padding: '2%',
    backgroundColor: 'white',
    textAlign: 'center',
    color: '#fff',
    borderBottom: '2px solid #a3a3a3',
    marginBottom: '2%'
  },
  headerText: {
    color: 'black',
    margin: 0,
    fontSize: '2.5vw',
  },
  formContainer: {
    width: '90%',
    maxWidth: '600px',
    padding: '3%',
    border: '1px solid #ccc',
    borderRadius: '10px',
    backgroundColor: '#f9f9f9',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
  },
  stepIndicator: {
    textAlign: 'center',
    marginBottom: '3%',
    fontSize: '1.2em',
    color: '#333',
  },
  stepContainer: {
    width: '100%',
  },
  heading: {
    textAlign: 'center',
    marginBottom: '3%',
    fontSize: '1.8em',
    color: '#333',
  },
  formGroup: {
    marginBottom: '3%',
  },
  label: {
    display: 'block',
    marginBottom: '1%',
    fontSize: '1em',
    color: '#555',
  },
  input: {
    width: '100%',
    padding: '2%',
    fontSize: '1em',
    borderRadius: '5px',
    border: '1px solid #ccc',
    boxSizing: 'border-box',
  },
  select: {
    width: '100%',
    padding: '2%',
    fontSize: '1em',
    borderRadius: '5px',
    border: '1px solid #ccc',
    boxSizing: 'border-box',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '4%',
    marginBottom: '4%',
  },
  button: {
    padding: '2% 4%',
    fontSize: '1em',
    backgroundColor: '#007bff',
    color: '#fff',
    borderRadius: '5px',
    border: 'none',
    cursor: 'pointer',
    minHeight: '40px',
  },
  submitButton: {
    padding: '2% 4%',
    fontSize: '1.1em',
    backgroundColor: '#28a745',
    color: '#fff',
    borderRadius: '5px',
    border: 'none',
    cursor: 'pointer',
    minHeight: '40px',
  },
  disqualifiedMessage: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#f8d7da',
    color: '#721c24',
    padding: '3%',
    borderRadius: '5px',
    textAlign: 'center',
    zIndex: 1000,
    width: '80%',
    maxWidth: '400px',
  }
};


export default ProspectiveTenantForm;
