import React, { useState, useEffect } from 'react';
import './BestValueProperties.css';
import { fetchProperties, addProperty, removeProperty } from './Database'; // assuming Database.js is in the same directory
import Neighborhoods from './chicago_hoods.json'

const BestValueProperties = () => {
  const [droppedCards, setDroppedCards] = useState([]);
  const [calculatedProperty, setCalculatedProperty] = useState();
  const numberFormat = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });
  const [properties, setProperties] = useState(null);
  const [calculatedProperties, setCalculatedProperties] = useState(null);

  const [potentialMonthlyDataMetrics, setPotentialMonthlyDataMetrics] = useState({
    potentialMonthlyRent: 0,
    potentialMonthlyRevenue: 0,
    potentialMonthlyMortgageRate: 0
  });

  // TODO persist Best Value Properties to the database

  useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchedProperties = await fetchProperties();
        setProperties(fetchedProperties);
        // Update calculatedProperties with transformed data
        const transformedProperties = mapFetchedProperties(fetchedProperties);
        setCalculatedProperties(transformedProperties);
      } catch (err) {
        console.error(err);
      }
    };

    fetchData();
  }, []);

  const mapFetchedProperties = (fetchedProperties) => {
    const transformedProperties = fetchedProperties.map((property) => {
      const address = property.get('address');
      const objectId = property.id;
      const potentialMonthlyMortgageRate = property.get('potentialMonthlyMortgageRate');
      const potentialMonthlyRevenue = property.get('potentialMonthlyRevenue');
      const potentialMonthlyRent = property.get('potentialMonthlyRent');
      const potentialLifeTimeLoanCost = property.get('potentialLifeTimeLoanCost');
      const homeOwnersInsurance = property.get('homeOwnersInsurance') || 0; 
      const propertyTaxes = property.get('propertyTaxes') || 0; 
      const lender = property.get('lender'); 
      const propertyUrl = property.get('propertyUrl');
      const zipCode = property.get('zipCode');
      const miscellaneousCost = property.get('miscellaneousCost') || 0;
      const currentRents = property.get('currentRents') || 0;
      const notes = property.get('notes');


      return {
        objectId: objectId,
        address: address,
        potentialMonthlyRent: potentialMonthlyRent,
        potentialMonthlyMortgageRate: potentialMonthlyMortgageRate,
        potentialMonthlyRevenue: potentialMonthlyRevenue,
        potentialLifeTimeLoanCost: potentialLifeTimeLoanCost,
        homeOwnersInsurance: homeOwnersInsurance,
        propertyTaxes: propertyTaxes,
        lender: lender,
        propertyUrl: propertyUrl,
        zipCode: zipCode,
        miscellaneousCost: miscellaneousCost,
        currentRents: currentRents,
        notes: notes
      };
    });

    return transformedProperties;
  };

  const getNeighborhood = (zipCode) => {
    const neighborhood = Neighborhoods[zipCode];
    return neighborhood || "N/A";
  };
  
  const handleDragOver = (e) => {
    let toDelete = "nothing";

    if (properties && properties.length > 0) {
      toDelete = properties[0].get('address');
    }

    console.log(toDelete);
    e.preventDefault();
  };

  const handleDrop = async (e) => {
    e.preventDefault();
    const cardData = e.dataTransfer.getData('text/plain');
    setDroppedCards((prevCards) => [...prevCards, cardData]);
  
    const jsonData = e.dataTransfer.getData('application/json');
    const data = JSON.parse(jsonData);
  
    await addProperty(data);
  
    // Fetch the updated properties from the database
    const fetchedProperties = await fetchProperties();
  
    // Update the properties state with the newly fetched data
    setProperties(fetchedProperties);
  
    // Update the calculatedProperties state with the transformed data
    const transformedProperties = mapFetchedProperties(fetchedProperties);
    setCalculatedProperties(transformedProperties);
  };
  
  const handleDelete = async (objectId) => {
    // Remove the property from the database
    await removeProperty(objectId);
  
    // Update the properties state by filtering out the deleted property
    setProperties((prevProperties) => prevProperties.filter((property) => property.objectId !== objectId));
  
    // Update the calculatedProperties state by filtering out the deleted property
    setCalculatedProperties((prevCalculatedProperties) =>
      prevCalculatedProperties.filter((property) => property.objectId !== objectId)
    );
  };

  // Check if calculatedProperties is null before sorting
  const sortedProperties =
    calculatedProperties !== null
      ? calculatedProperties.sort((a, b) => b.potentialMonthlyRevenue - a.potentialMonthlyRevenue)
      : [];

  return (
    <div className="best-value-properties">
      <h2>Best Value Properties</h2>
      <div className="drop-area" onDragOver={handleDragOver} onDrop={handleDrop}>
        {calculatedProperties !== null && calculatedProperties.length > 0 ? (
          sortedProperties.map((property, index) => (
            <div key={index} className="dropped-card">
              <button onClick={() => handleDelete(property.objectId)} style={{ float: 'right' }}>
                X
              </button>
              <h2>{property.address || 'Property Summary'}</h2>
              <p>Rank: {index + 1}</p>
              <p>Zip Code: {property.zipCode}</p>
              <p>Neighborhood: {getNeighborhood(property.zipCode)}</p>
              <p>Home Owners Insurance: {numberFormat.format(property.homeOwnersInsurance) || 0}</p>
              <p>Property Taxes: {numberFormat.format(property.propertyTaxes) || 0}</p>
              <p>Property Url: <a href={property.propertyUrl} target="_blank" rel="noopener noreferrer">{property.propertyUrl}</a></p>
              <p>Lender: {property.lender}</p>
              <p>Miscellaneous Costs: {numberFormat.format(property.miscellaneousCost) || 0}</p>
              <p>Potential Monthly Mortgage Rate: {numberFormat.format(property.potentialMonthlyMortgageRate) || 0}</p>
              <p>Potential Monthly Rent: {numberFormat.format(property.potentialMonthlyRent) || 0}</p>
              <p>Current Monthly Tenant Rent: {numberFormat.format(property.currentRents) || 0}</p>
              <p>Potential LifeTime Loan Cost: {numberFormat.format(property.potentialLifeTimeLoanCost) || 0}</p>
              <p>Potential Monthly Revenue: {numberFormat.format(property.potentialMonthlyRevenue) || 0}</p>
              <p>Notes: {property.notes}</p>
            </div>
          ))
        ) : (
          <p>Drag and drop property cards here</p>
        )}
      </div>
    </div>
  );
};

export default BestValueProperties;
