import React, { useState, useEffect } from 'react';
import { getLeads } from './Database';

const LeadRow = ({ lead }) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <>
      <tr onClick={() => setExpanded(!expanded)} style={styles.clickableRow}>
        <td style={styles.td}>{lead.firstName}</td>
        <td style={styles.td}>{lead.lastName}</td>
        <td style={styles.td}>{new Date(lead.createdAt).toLocaleDateString()}</td>
        <td style={styles.td}>{lead.emailAddress}</td>
        <td style={styles.td}>{lead.phoneNumber}</td>
        <td style={styles.td}>{lead.creditScore}</td>
        <td style={styles.td}>{lead.annualIncome}</td>
      </tr>
      {expanded && (
        <tr>
          <td colSpan="6" style={styles.expandedRow}>
            <p>Current Address: {lead.currentAddress}</p>
            <p>Employment Status: {lead.employmentStatus}</p>
            <p>Reason for Moving: {lead.reasonForMoving}</p>
            <p>Preferred Move-In Date: {lead.preferredMoveInDate}</p>
            <p>Number of Occupants: {lead.numberOfOccupants}</p>
            <p>Have You Been Evicted: {lead.haveYouBeenEvicted}</p>
            <p>Criminal History: {lead.criminalHistory}</p>
          </td>
        </tr>
      )}
    </>
  );
};

const Leads = () => {
  const [qualifiedLeads, setQualifiedLeads] = useState([]);
  const [unqualifiedLeads, setUnqualifiedLeads] = useState([]);

  useEffect(() => {
    const fetchLeads = async () => {
      try {
        const fetchedLeads = await getLeads();
        console.log('Fetched leads:', fetchedLeads);
        const qualified = fetchedLeads.filter(lead => lead.qualified === true);
        const unqualified = fetchedLeads.filter(lead => lead.qualified !== true);
        setQualifiedLeads(qualified);
        setUnqualifiedLeads(unqualified);
      } catch (error) {
        console.error('Error fetching leads:', error);
      }
    };

    fetchLeads();
  }, []);

  return (
    <div style={styles.container}>
      <h2 style={styles.heading}>Leads</h2>
      <div style={styles.leadsSection}>
        <h3 style={styles.subHeading}>Qualified Leads</h3>
        <table style={styles.table}>
          <thead>
            <tr>
              <th style={styles.th}>First Name</th>
              <th style={styles.th}>Last Name</th>
              <th style={styles.th}>Created At</th>
              <th style={styles.th}>Email</th>
              <th style={styles.th}>Phone Number</th>
              <th style={styles.th}>Credit Score</th>
              <th style={styles.th}>Annual Income</th>
            </tr>
          </thead>
          <tbody>
            {qualifiedLeads.length > 0 ? (
              qualifiedLeads.map((lead, index) => (
                <LeadRow key={index} lead={lead} />
              ))
            ) : (
              <tr>
                <td style={styles.td} colSpan="6">No qualified leads available</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div style={styles.leadsSection}>
        <h3 style={styles.subHeading}>Unqualified Leads</h3>
        <table style={styles.table}>
          <thead>
            <tr>
              <th style={styles.th}>First Name</th>
              <th style={styles.th}>Last Name</th>
              <th style={styles.th}>Created At</th>
              <th style={styles.th}>Email</th>
              <th style={styles.th}>Phone Number</th>
              <th style={styles.th}>Credit Score</th>
              <th style={styles.th}>Annual Income</th>
            </tr>
          </thead>
          <tbody>
            {unqualifiedLeads.length > 0 ? (
              unqualifiedLeads.map((lead, index) => (
                <LeadRow key={index} lead={lead} />
              ))
            ) : (
              <tr>
                <td style={styles.td} colSpan="6">No unqualified leads available</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const styles = {
  container: {
    padding: '20px',
    backgroundColor: '#f9f9f9',
    borderRadius: '10px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    marginTop: '20px',
  },
  heading: {
    textAlign: 'center',
    fontSize: '24px',
    marginBottom: '20px',
    color: '#333',
  },
  leadsSection: {
    marginBottom: '30px',
  },
  subHeading: {
    fontSize: '20px',
    marginBottom: '10px',
    color: '#555',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  th: {
    borderBottom: '2px solid #ddd',
    padding: '10px',
    textAlign: 'left',
    backgroundColor: '#f2f2f2',
    color: '#333',
  },
  td: {
    borderBottom: '1px solid #ddd',
    padding: '10px',
    color: '#555',
  },
  clickableRow: {
    cursor: 'pointer',
  },
  expandedRow: {
    backgroundColor: '#f0f0f0',
    padding: '10px',
  },
};

export default Leads;
