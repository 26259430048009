import React, { createContext, useState, useContext } from 'react';

const FormDataContext = createContext();

export const FormDataProvider = ({ children }) => {
    const [qualifiedLeads, setQualifiedLeads] = useState([]);
    const [unqualifiedLeads, setUnqualifiedLeads] = useState([]);
    const [formData, setFormData] = useState({});

  return (
    <FormDataContext.Provider value={{ qualifiedLeads, unqualifiedLeads, setQualifiedLeads, setUnqualifiedLeads }}>
      {children}
    </FormDataContext.Provider>
  );
};

export const useFormData = () => {
  const context = useContext(FormDataContext);
  if (!context) {
    throw new Error('useFormData must be used within a FormDataProvider');
  }
  return context;
};
