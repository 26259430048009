import React, { useContext, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { AuthContext } from './AuthContext';

const Login = () => {
  const { setIsAuthenticated } = useContext(AuthContext);
  const [passcode, setPasscode] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogin = () => {
    if (passcode === '0000') {
      setIsAuthenticated(true);
      const intendedPath = location.state?.from?.pathname || '/prospectivetenant';
      navigate(intendedPath);
    } else {
      alert('Incorrect passcode. Please try again.');
    }
  };

  return (
    <div className="login-container">
      <h1 style={{ fontStyle: 'italic', fontWeight: 'bold' }}>Eastwood Technologies LLC</h1>
      <h2>
        <i>Real-Estate Tools</i>
      </h2>
      <h3>Enter a passcode to continue</h3>
      <input
        style={{ marginBottom: '2em' }}
        type="password"
        value={passcode}
        onChange={(e) => setPasscode(e.target.value)}
      />
      <button onClick={handleLogin}>Confirm</button>
    </div>
  );
};

export default Login;
