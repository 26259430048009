import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './App.css';
import BestValueProperties from './BestValueProperties';

export const calculatePotentialAverageRent = (allocateBedrooms, detailedData) => {
  let potentialAverageRent = 0;

  const detailedDataDict = detailedData.reduce((acc, item) => {
    acc[item.bedrooms] = item.averageRent;
    return acc;
  }, {});

  allocateBedrooms.forEach((bedrooms) => {
    potentialAverageRent += parseFloat(detailedDataDict[bedrooms]) || 0;
  });

  return potentialAverageRent;
};

export const allocateBedrooms = (numBedrooms, numStories) => {
  numBedrooms = parseInt(numBedrooms, 10);
  numStories = parseInt(numStories, 10);

  if (numBedrooms <= 0 || numStories <= 0) {
    return [];
  }

  const maxBedroomsPerStory = 7;
  const avgBedrooms = Math.floor(numBedrooms / numStories);
  const remainder = numBedrooms % numStories;

  const bedroomsPerStory = Array(numStories).fill(avgBedrooms);

  for (let i = 0; i < remainder; i++) {
    if (bedroomsPerStory[i] < maxBedroomsPerStory) {
      bedroomsPerStory[i]++;
    }
  }

  return bedroomsPerStory;
};

const InvestmentPropertyCalculator = () => {
  const [zipCode, setZipCode] = useState('');
  const [bedrooms, setBedrooms] = useState('');
  const [units, setUnits] = useState('');
  const [price, setPrice] = useState('');
  const [mortgageTerm, setMortgageTerm] = useState(30);
  const [interestRate, setInterestRate] = useState(7.1);
  const [address, setAddress] = useState('');
  const [propertyTaxes, setPropertyTaxes] = useState('');
  const [homeOwnersInsurance, setHomeOwnersInsurance] = useState('');

  const [calculatedProperties, setCalculatedProperties] = useState([]);
  const [calculatedProperty, setCalculatedProperty] = useState(null);
  const [miscellaneousCost, setMiscellaneousCost] = useState('');
  const [currentRents, setCurrentRents] = useState('');

  const [notes, setNotes] = useState('');



  const[lender, setLender] = useState('FifthThirdBank');
  const[propertyUrl, setPropertyUrl] = useState('www.redfin.com');

  const numberFormat = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });
  const [potentialMonthlyDataMetrics, setPotentialMonthlyDataMetrics] = useState({
    potentialMonthlyRent: 0,
    potentialMonthlyRevenue: 0,
    potentialMonthlyMortgageRate: 0,
    potentialLifeTimeLoanCost: 0,
  });

  const calculateLifeTimeLoanSpend = (potentialMonthlyMortgageRate, mortgageTerm) => {
    return potentialMonthlyMortgageRate * 12 * mortgageTerm;
  };

  useEffect(() => {
    const updateDataTransfer = (e) => {
      e.dataTransfer.setData('text/plain', 'potentialRent');
      e.dataTransfer.setData('application/json', JSON.stringify(potentialMonthlyDataMetrics));
    };

    document.addEventListener('dragstart', updateDataTransfer);

    return () => {
      document.removeEventListener('dragstart', updateDataTransfer);
    };
  }, [potentialMonthlyDataMetrics]);

  const draggableCard = (
    <div
      className="draggable-card"
      draggable
      onDragStart={(e) => {
        e.dataTransfer.setData('text/plain', 'potentialRent');
        e.dataTransfer.setData('application/json', JSON.stringify(potentialMonthlyDataMetrics));
      }}
    >
      <h2>{calculatedProperty?.address || 'Property Summary'}</h2>
      <p>Home Owners Insurance: {numberFormat.format(parseFloat(calculatedProperty?.homeOwnersInsurance) || 0)}</p>
      <p>Property Taxes: {numberFormat.format(parseFloat(calculatedProperty?.propertyTaxes) || 0)}</p>
      <p>Potential Monthly Mortgage Rate: {numberFormat.format(calculatedProperty?.potentialMonthlyMortgageRate || 0)}</p>
      <p>Potential Monthly Rent: {numberFormat.format(calculatedProperty?.potentialMonthlyRent || 0)}</p>
      <p>Potential LifeTime Loan Spend: {numberFormat.format(calculatedProperty?.potentialLifeTimeLoanCost || 0)}</p>
      <p>Potential Monthly Revenue: {numberFormat.format(calculatedProperty?.potentialMonthlyRevenue || 0)}</p>
      
    </div>
  );

  const handleCalculate = async () => {
    try {
      const response = await axios.get(`https://realty-mole-property-api.p.rapidapi.com/zipCodes/${zipCode}`, {
        headers: {
          // 'X-RapidAPI-Key': '4bb806d9d6msh0dd40911ba8242dp13159ajsn3e0c2bb566dd',
          // 'X-RapidAPI-Host': 'realty-mole-property-api.p.rapidapi.com',
          'X-RapidAPI-Key': '953681341fmsh046bca63a89d963p156e40jsn90cd5a2097cb',
          'X-RapidAPI-Host': 'realty-mole-property-api.p.rapidapi.com'
        },
      });

      const getMostRecentMonthData = getMostRecentMonth(response.data);
      const detailedByBedrooms = response.data.rentalData.history[getMostRecentMonthData].detailed;

      const allocateBedroomsResult = allocateBedrooms(bedrooms, units);

      const potentialAverageRent = calculatePotentialAverageRent(allocateBedroomsResult, detailedByBedrooms);

      const potentialMonthlyMortgageRate = calculateMonthlyMortgage(price, interestRate);

      const potentialMonthlyRevenue =
        potentialAverageRent - potentialMonthlyMortgageRate - parseFloat(homeOwnersInsurance) - parseFloat(propertyTaxes);

      const potentialLifeTimeLoanCost = calculateLifeTimeLoanSpend(potentialMonthlyMortgageRate, mortgageTerm);

      setPotentialMonthlyDataMetrics({
        potentialMonthlyMortgageRate: potentialMonthlyMortgageRate,
        potentialMonthlyRevenue: potentialMonthlyRevenue,
        potentialMonthlyRent: potentialAverageRent,
        address: address,
        potentialLifeTimeLoanCost: calculateLifeTimeLoanSpend(potentialMonthlyMortgageRate, mortgageTerm),
        homeOwnersInsurance: parseFloat(homeOwnersInsurance),
        propertyTaxes: parseFloat(propertyTaxes),
        lender: lender,
        propertyUrl: propertyUrl,
        zipCode: zipCode,
        currentRents: parseInt(currentRents),
        miscellaneousCost: parseInt(miscellaneousCost),
        notes: notes
      });

      const newCalculatedProperty = {
        potentialMonthlyRent: potentialAverageRent.toFixed(2),
        potentialMonthlyMortgageRate: potentialMonthlyMortgageRate.toFixed(2),
        potentialMonthlyRevenue: potentialMonthlyRevenue.toFixed(2),
        address: address,
        potentialLifeTimeLoanCost: potentialLifeTimeLoanCost.toFixed(2),
        homeOwnersInsurance: homeOwnersInsurance,
        propertyTaxes: propertyTaxes,
        lender: lender,
        propertyUrl: propertyUrl,
        zipCode: zipCode,
        currentRents: parseInt(currentRents),
        miscellaneousCost: parseInt(miscellaneousCost),
        notes: notes
      };

      setCalculatedProperty(newCalculatedProperty);
      setCalculatedProperties([...calculatedProperties, newCalculatedProperty]);
    } catch (error) {
      console.error('Error:', error.message);
    }
  };

  const getMostRecentMonth = (data) => {
    const rentalHistory = data.rentalData.history;
    const months = Object.keys(rentalHistory);
    const mostRecentMonth = months.reduce((maxMonth, currentMonth) => {
      if (currentMonth > maxMonth) {
        return currentMonth;
      }
      return maxMonth;
    });
    return mostRecentMonth;
  };

  const calculateMonthlyMortgage = (price, interestRate) => {
    const loanAmount = price; // Assume no down payment
    const months = mortgageTerm * 12; // Mortgage term converted to months
    const monthlyInterestRate = (interestRate / 100) / 12; // Convert annual interest rate to monthly rate

    const monthlyPayment =
      (loanAmount * (monthlyInterestRate * Math.pow(1 + monthlyInterestRate, months))) /
      (Math.pow(1 + monthlyInterestRate, months) - 1);

    return monthlyPayment;
  };

  const validateInputs = () => {
    const isZipCodeValid = /^\d{5}$/.test(zipCode);
    const isBedroomsValid = /^\d+$/.test(bedrooms);
    const isUnitsValid = /^\d+$/.test(units);
    const isPriceValid = /^\d+$/.test(price);
    const isMortgageTermValid = /^\d+$/.test(mortgageTerm);
    const isInterestRateValid = /^\d+(\.\d{1,2})?$/.test(interestRate);

    return (
      isZipCodeValid &&
      isBedroomsValid &&
      isUnitsValid &&
      isPriceValid &&
      isMortgageTermValid &&
      isInterestRateValid
    );
  };

  return (
    <div className="container">
      <div className="calculator-container">
        <h2>Investment Property Calculator</h2>

        <div>
          <label className="label">Address:</label>
          <input className="input" type="text" value={address} onChange={(e) => setAddress(e.target.value)} />
        </div>

        <div>
          <label className="label">Zip Code:</label>
          <input className="input" type="text" value={zipCode} onChange={(e) => setZipCode(e.target.value)} />
        </div>

        <div>
          <label className="label">Bedrooms:</label>
          <input className="input" type="text" value={bedrooms} onChange={(e) => setBedrooms(e.target.value)} />
        </div>

        <div>
          <label className="label">Units:</label>
          <input className="input" type="text" value={units} onChange={(e) => setUnits(e.target.value)} />
        </div>

        <div>
          <label className="label">Price:</label>
          <input className="input" type="text" value={price} onChange={(e) => setPrice(e.target.value)} />
        </div>

        <div>
          <label className="label">Mortgage Term: Years</label>
          <input className="input" type="text" value={mortgageTerm} onChange={(e) => setMortgageTerm(e.target.value)} />
        </div>

        <div>
          <label className="label">Interest Rate:</label>
          <input className="input" type="text" value={interestRate} onChange={(e) => setInterestRate(e.target.value)} />
        </div>

        <div>
          <label className="label">Home Owners Insurance:</label>
          <input className="input" type="text" value={homeOwnersInsurance} onChange={(e) => setHomeOwnersInsurance(e.target.value)} />
        </div>

        <div>
          <label className="label">Property Taxes:</label>
          <input className="input" type="text" value={propertyTaxes} onChange={(e) => setPropertyTaxes(e.target.value)} />
        </div>

        <div>
          <label className="label">Current Rent Totals:</label>
          <input className="input" type="text" value={currentRents} onChange={(e) => setCurrentRents(e.target.value)} />
        </div>

        <div>
          <label className="label">Miscellaneous Costs:</label>
          <input className="input" type="text" value={miscellaneousCost} onChange={(e) => setMiscellaneousCost(e.target.value)} />
        </div>

        <div>
          <label className="label">Lender:</label>
          <input className="input" type="text" value={lender} onChange={(e) => setLender(e.target.value)} />
        </div>

        <div>
          <label className="label">Property URL: </label>
          <input className="input" type="text" value={propertyUrl} onChange={(e) => setPropertyUrl(e.target.value)} />
        </div>

        <div className="notes">
          <label className="label">Notes</label>
          <textarea className="input" value={notes} onChange={(e) => setNotes(e.target.value)} />
        </div>

        <button className="button" onClick={handleCalculate} disabled={!validateInputs()}>
          Calculate
        </button>

        <div className="result">{draggableCard}</div>
      </div>

      <div className="best-value-container">
        <BestValueProperties calculatedProperties={calculatedProperties} />
      </div>
    </div>
  );
};

export default InvestmentPropertyCalculator;





